import { set } from "js-cookie";

setInterval(createSnowflake, 100);

function createSnowflake() {
  // gets banner
  let banner = document.querySelector(".snowflakes");

  // creates a span for snowflake
  let snowflake = document.createElement("span");
  let snowIcon = "<span style='color:white;fontWeight:bold'>&#10052;</span> ";
  const bannerHeight = banner.clientHeight;

  // medium height banner / hero
  if (bannerHeight < 500 && bannerHeight > 300) {
    snowflake.style.animationDuration = Math.random() * 4 + 4 + "s";
    setTimeout(() => {
      snowflake.remove();
    }, 8000);
    // Large banner / hero
  } else if (bannerHeight > 500) {
    snowflake.style.animationDuration = Math.random() * 8 + 8 + "s";
    setTimeout(() => {
      snowflake.remove();
    }, 16000);
    // small banner / hero (mobile view)
  } else {
    snowflake.style.animationDuration = Math.random() * 2 + 3 + "s";
    setTimeout(() => {
      snowflake.remove();
    }, 5000);
  }

  snowflake.classList.add("snowflake");
  snowflake.style.left = Math.random() * window.innerWidth + "px";
  snowflake.style.opacity = Math.random();
  snowflake.style.fontSize = Math.random() * 10 + 20 + "px";
  snowflake.innerHTML = snowIcon;
  banner.appendChild(snowflake);
}

export default createSnowflake;
//
